import { Injectable, isDevMode } from "@angular/core";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";
import{AuthService} from '../services/auth.service'
import * as configData from '../../shared/data/config-data';
export interface ITemplateConfig
{
    layout: {
        menuPosition: string; // options: Side, Top (Note: Use 'Side' for Vertical Menu & 'Top' for Horizontal Menu )
        navbar: { type: string;                     // options: Static & Fixed
             }
        sidebar: { //Options for Vertical Side menu
            collapsed: boolean;             // options: true, false
            size: string;                   // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
        }
    };
}
let search: string;
let app: string;
@Injectable({providedIn: "root"})
export class ConfigService {
  public templateConf: ITemplateConfig = this.setConfigValue();
  templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();
    public domainGlobals = new Subject<object>();
  domainGlobals$ = this.domainGlobals.asObservable();
  public whoAmI = new Subject<object>();
  whoAmI$ = this.whoAmI.asObservable();
    public domainGlobalsList: any;
    public noticeToUsers : any;
    public appRoutes : any;
   public user: any;
   public role: any;
  constructor(
      public authService: AuthService,
  ) {
        if (isDevMode()) {
            // dah cli dev mode
           this.appRoutes = configData.appRoutes.develop;
        } else {
            if (location.hostname === "localhost"){this.appRoutes = configData.appRoutes.prod_local}
            else {
                if(window.location.href.indexOf("my-stg") != -1){this.appRoutes = configData.appRoutes.staging}
                else {
                    if(window.location.href.indexOf("my.alcf") != -1){this.appRoutes = configData.appRoutes.production} else {this.appRoutes = configData.appRoutes.production_internal}
                }
            }
        }
     this.authService.domainGlobals().subscribe(domainGlobals => {
         this.domainGlobals.next(domainGlobals);
          this.domainGlobalsList = domainGlobals;
      });
this.authService.whoAm$.subscribe(user => {
      this.whoAmI.next(user);
      this.user = user;
      this.role = user['role'];
      });
      this.noticeToUsers = configData.noticeToUsers;
  }

  buildMenuData(cb){
      if(!app){app = document.body.id.toLowerCase()}
      let appRoutes = this.appRoutes
      let tree = [];
        if (app === 'portal') {
            tree.push({
                path: '/dashboard',
                title: 'Home',
                icon: 'fa-sharp fa-light fa-house',
                id: 'portal-home-icon',
                class: '',
                isExternalLink: false,
                submenu: []
            })
        } else {
            if (app !== 'nisrv') {
                tree.push({
                    path: appRoutes.portal + '/#/dashboard',
                    title: 'Home',
                    icon: 'fa-sharp fa-light fa-house',
                    id: 'home-icon',
                    class: '',
                    isExternalLink: true,
                    submenu: []
                })
            }
        }
        if (app.startsWith('ni')) {search = '=';
        } else {search = '%3D'}
        if (app !== 'nisrv') {
            tree.push(
                {
                    path: appRoutes.ub3 + '/#/accountUpdate',
                    title: 'Account Update',
                    icon: 'fa-light fa-user',
                    id:'alcf-account-update-icon',
                    class: '',
                    isExternalLink: true,
                    submenu: []
                },
                {
                    path: '',
                    title: 'Projects & Resources',
                    icon: 'fa-sharp fa-light fa-list-check',
                    id: 'alcf-project-resources-icon',
                    class: 'has-sub',
                    isExternalLink: false,
                    submenu: [{
                        path: appRoutes.ub3 + '/#/manageProjects',
                        title: 'Project Management',
                        icon: '',
                        id: 'alcf-project-mgmt-icon',
                        class: '',
                        isExternalLink: true,
                        submenu: []
                    },
                        {
                            path: appRoutes.ub3 + '/#/joinProject',
                            title: "Join project",
                            icon: '',
                            id: 'alcf-join-project-icon',
                            class: '',
                            isExternalLink: true,
                            submenu: []
                        },
                         {
                            path: appRoutes.ub3 + '/#/systemRequest',
                             title: "View Systems",
                            icon: '',
                             id: 'alcf-view-systems-icon',
                            class: '',
                            isExternalLink: true,
                            submenu: []
                        },
                        {
                            path: appRoutes.ub3 + '/#/manageUnixGroups',
                            title: "Manage UNIX Groups",
                            icon: '',
                            id: 'alcf-manage-unix-grp-icon',
                            class: '',
                            isExternalLink: true,
                            submenu: []
                        },
                        {
                            path: appRoutes.ub3 + '/#/allocationRequests',
                            title: "Request an Allocation",
                            icon: '',
                            id: 'alcf-request-allocation-icon',
                            class: '',
                            isExternalLink: true,
                            submenu: []
                        },
                    ]
                },
/*                {
                    path: appRoutes.arm,
                    title: 'Allocations',
                    icon: 'fa-light fa-chart-pie-simple',
                    id: 'arm-icon',
                    class: '',
                    isExternalLink: app !=='arm',
                    submenu: []
                },*/
            )
        }
        tree.push(
            {
                path: (app.startsWith('ni') ? '' : appRoutes.ni + '/#') + '/list/:command' + search + 'sbank-list-allocations',
                title: 'Sbank',
                icon: 'fa-light fa-building-columns',
                id: 'ni-icon',
                class: '',
                isExternalLink: app !=='ni',
                submenu: []
            },
        );
        tree.push(
            {
                path: 'https://www.alcf.anl.gov/support-center/training-assets',
                title: 'Training',
                icon: 'fa-light fa-user-graduate',
                id: 'training',
                class: '',
                isExternalLink: true,
                outsideMvp: true,
                submenu: []
            },
        );
        tree.push(
            {
                path: 'https://docs.alcf.anl.gov',
                title: 'User Guides',
                icon: 'fa-light fa-book',
                id: 'training',
                class: '',
                isExternalLink: true,
                outsideMvp: true,
                submenu: []
            },
        );

        this.isDomainAdmin(async (r) => {
             if (r) {
                 tree.push({
                     path: '',
                     title: 'Administration',
                     icon: 'fa-sharp fa-light fa-lock',
                     id: 'alcf-admin-icon',
                     class: 'has-sub',
                     isExternalLink: false,
                     submenu: [{
                         path: appRoutes.ub3 + '/#/userAdmin',
                         title: 'User Administration',
                         icon: '',
                         id: 'alcf-user-admin-icon',
                         class: '',
                         isExternalLink: true,
                         submenu: []
                     },
                         {
                             path: appRoutes.ub3 + '/#/createOtherAccounts',
                             title: "Create Other Accounts",
                             icon: '',
                             id: 'alcf-create-other-accounts-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/projectAdmin',
                             title: "Project Administration",
                             icon: '',
                             id: 'alcf-project-admin-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/aclAdmin',
                             title: "ACL Administration",
                             icon: '',
                             id: 'alcf-join-project-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/institutions',
                             title: "Institutions",
                             icon: '',
                             id: 'alcf-institutions-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/logsAdmin',
                             title: "Logs",
                             icon: '',
                             id: 'alcf-logs-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/pluginsAdmin',
                             title: "Plugins",
                             icon: '',
                             id: 'alcf-plugins-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/svcApi',
                             title: "Other Screens",
                             icon: '',
                             id: 'alcf-other-screens-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },
                         {
                             path: appRoutes.ub3 + '/#/apiDevInt',
                             title: "API Developer Interface",
                             icon: '',
                             id: 'alcf-api-dev-icon',
                             class: '',
                             isExternalLink: true,
                             submenu: []
                         },

                     ]
                 })
             }
              });
                     cb(tree);
        //return tree
  }

  setConfigValue() {
    return this.templateConf = {
      layout: {
        menuPosition: "Side",
        navbar: {
          type: 'Static'
        },
        sidebar: {
          collapsed: true,
          size: "sidebar-lg",
        }
      }
    };
  }


  applyTemplateConfigChange(tempConfig: ITemplateConfig) {
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    this.templateConfSubject.next(this.templateConf);
  }
  isDomainAdmin(cb){
      if (this.role){if(this.role == 'domainAdmin'){cb(true)}}
  else {this.authService.whoAmI().subscribe(user => {if (user['role'] == 'domainAdmin'){cb(true)}})}
          }
}
